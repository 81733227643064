window.eventsTracking = function(eventName) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "GA4_eventTracking",
        eventName: eventName
    });
};

window.destinationEventsTracking = function(eventName, destinationName) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "GA4_eventTracking",
        eventName: eventName,
        destinationName: destinationName,
})
};
